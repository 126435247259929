import React from "react"
import { Box } from '@mui/material';
import CodeReadingExperiment from '../../components/experiments/code-reading-experiment/code-reading-experiment';

export default () => {
    return (
        <Box mx={2}>
            <CodeReadingExperiment uuid="e57cfdbd-cc1e-4cc9-96dc-9b5a13b41c4e" />
        </Box>
    );
};